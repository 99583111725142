<template>
  <div class="container">
    <div>
      <form
        v-if="!isMutiFactor"
        class="modal-content animate"
        @submit.prevent="loginUser"
        novalidate
      >
        <div class="imgcontainer">
          <img
            width="300"
            src="https://www.maropost.com/wp-content/themes/Maropost/img/logos/Maropost_Logo.svg"
            alt="Avatar"
          />
          <br />
          <h1 class="red">Login</h1>
        </div>

        <div class="container">
          <label for="uname"><b>Email</b></label>
          <input
            v-model="credentials.email"
            type="text"
            placeholder="Enter Email"
            name="uname"
            class="form-control"
            required
          />

          <label for="psw"><b>Password</b></label>
          <input
            type="password"
            v-model="credentials.password"
            placeholder="Enter Password"
            name="psw"
            class="form-control"
            required
          />

          <button type="submit">
            {{ isLoading ? "Loading...." : "Login" }}
          </button>
          <div class="mt-4">
            <alert-message
              v-if="!!errorMessage"
              :text="errorMessage"
              type="danger"
            />
          </div>
        </div>
      </form>
    </div>

    <alert-message
      v-if="isSmsCodeSent"
      :text="
        `Sms code have been sent your number ${mutiFactorDetails.phoneNumber}`
      "
    />

    <muti-factor-form
      v-if="isMutiFactor"
      :isSmsVerified="isMutiFactor"
      :isLoading="isLoading"
      :credentials="credentials"
      :showPhoneNumber="false"
      @submit="enrollMultiFactor"
    />
    <div id="recaptcha-container" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapMutations, mapActions } from "vuex";
import MutiFactorForm from "@/components/MutiFactorForm.vue";

import firebaseMixin from "@/mixins/firebase.mixin.js";
import AlertMessage from "@/components/shared/AlertMessage.vue";
const auth = firebase.auth();

/**
 * Sign in users
 */
export default {
  name: "SignIn",
  mixins: [firebaseMixin],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { MutiFactorForm, AlertMessage },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      credentials: { phoneNumber: "", verificationCode: "" },
      smsVerificationId: "",
      recaptchaVerified: false,
      recaptchaVerifier: null,
      recaptchaVerifiedToken: "",
      multiFactorSession: null,
      isLoggedIn: false,
      isMutiFactor: false,
      authResolver: null,
      errorMessage: "",
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    mutiFactorDetails() {
      if (!this.authResolver) return {};
      return this.authResolver.hints[0];
    },
    isSmsCodeSent() {
      return !!this.smsVerificationId;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Maps mutations in component
     */
    ...mapMutations({
      setCurrentUser: "auth/SET_CURRENT_USER",
      setToken: "auth/SET_TOKEN",
      showLoader: "auth/SHOW_LOADER",
    }),
    /**
     * Maps action in component
     */
    ...mapActions({
      setCurrentUserDetails: "auth/setCurrentUserDetails",
    }),
    /**
     * Sends verification code to user phone number in the form
     */
    async sendVerificationMessage() {
      try {
        this.setIsLoading(true);
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider(auth);

        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          multiFactorHint: this.mutiFactorDetails,
          session: this.authResolver.session,
        };
        // Send SMS verification code.
        this.smsVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier
        );
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Shows loader to the user
     */
    setIsLoading(val) {
      this.showLoader(val);
      this.isLoading = val;
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        this.setIsLoading(true);
        const { verificationCode } = this.credentials;

        if (!this.smsVerificationId) {
          this.sendVerificationMessage();
        } else if (verificationCode) {
          this.verifySmsCode();
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verify user verification code code
     */
    async verifySmsCode() {
      // Ask user for the verification code.
      try {
        this.setIsLoading(true);
        var cred = firebase.auth.PhoneAuthProvider.credential(
          this.smsVerificationId,
          this.credentials.verificationCode
        );
        var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );

        // Complete enrollment. This will update the underlying tokens
        // and trigger ID token change listener.
        const userDetails = await this.authResolver.resolveSignIn(
          multiFactorAssertion
        );
        this.setCurrentUserDetails(userDetails);
      } catch (error) {
        if (error.code === "auth/invalid-verification-code") {
          this.showError(error);
        }
      } finally {
        this.setIsLoading(false);
      }
    },
    showError(error) {
      this.errorMessage = error?.message;
    },
    /**
     * Login current user
     */
    async loginUser() {
      try {
        this.setIsLoading(true);
        const { email, password } = this.credentials;

        await this.persistFirebaseUser();

        const userDetails = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);

        this.setCurrentUserDetails(userDetails);
      } catch (error) {
        switch (error?.code) {
          case "auth/multi-factor-auth-required":
            this.authResolver = error.resolver;
            this.isMutiFactor = true;
            this.enrollMultiFactor();
            break;
          case "auth/user-not-found":
            this.showError({ message: "User not found." });
            break;
          default:
            this.showError(error);
            break;
        }
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verifies and displayes recaptcha
     */
    verifyRecaptchaVerifier() {
      this.setIsLoading(true);

      const CAPTACHA_CONTAINER_ID = "recaptcha-container";
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        CAPTACHA_CONTAINER_ID,
        {
          size: "invisible",
          callback: function(response) {
            // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
            this.recaptchaVerified = !!response;
          },
        }
      );

      // this.recaptchaVerifier.render();
      this.setIsLoading(false);
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.verifyRecaptchaVerifier();
  },
};
</script>
